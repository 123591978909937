import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [

  {
    id: 3,
    label: 'Dashboard',
    link: '/dashboard',
    parentId: 2
  },
  {
    id: 4,
    label: 'Incidents',
    link: '/incident',
    parentId: 4
  },

];

